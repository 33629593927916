<template>
  <section class="homepage-section" v-if="recommend.length">
    <div class="container">
      <div class="section-header">
        <div class="section-header-start">
          <h2 class="title">{{ $t('recommend job') }}</h2>
        </div>
      </div>
      <div v-if="isLoading" class="loading-container">
        <div class="loading-item"></div>
        <div class="loading-item"></div>
        <div class="loading-item"></div>
      </div>
      <div class="swiper-container" v-else>
        <Swiper
          class="swiper"
          :modules="[SwiperAutoplay, SwiperNavigation]"
          :loop="false"
          :autoplay="{
            delay: 10000,
            disableOnInteraction: true
          }"
          navigation
          :breakpoints="swiperBreakpoint"
        >
          <SwiperSlide v-for="(i, index) in recommend" :key="index">
            <div class="recommend-job-card">
              <div class="cover">
                <img :src="i.image" alt="Cover" v-if="i.image" loading="lazy" />
                <span v-else><i class="fa-thin fa-image"></i></span>
              </div>
              <div class="card-content">
                <div class="card-content-header">
                  <div class="logo">
                    <img
                      :src="getUrlImage + i.logo"
                      alt="logo"
                      style="width: 35px; height: 35px"
                    />
                  </div>
                  <div class="text-info">
                    <h1>{{ i.companyName }}</h1>
                    <p>{{ timeCal(i.openingDate) }}</p>
                  </div>
                </div>
                <div class="card-content-body">
                  <nuxt-link :to="'/job_detail/' + i._id">
                    <h1>{{ i.title }}</h1>
                  </nuxt-link>

                  <div class="details">
                    <ul>
                      <li>
                        {{ i.workingLocations }}
                      </li>
                      <li>
                        {{ formateDate(i.openingDate) }} -
                        {{ formateDate(i.closingDate) }}
                      </li>
                    </ul>

                    <!-- <SaveJob
                    :JobId="i._id"
                    :isSave="i.isSaved"
                    @setdata="updateData"
                  /> -->
                    <div
                      class="saveJob-container"
                      @click="handleSaveJob(i)"
                      :class="{ saved: i.isSaved }"
                    >
                      <i class="fa-regular fa-heart"></i>
                      <p v-if="i.isSaved">{{ $t('saved') }}</p>
                      <p v-else>{{ $t('save job') }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import SaveJob from '~/components/reuse/saveJob.vue'
import { useAuthStore } from '~/store/auth'
import { formateDate, duration } from '~/services/formatDate'
import useFetchCustom from '@/services/global-useFetch'
import { useManageJobStore } from '@/store/manageJob'
const authStore = useAuthStore()
const { saveJob } = useManageJobStore()
// set time control
const timeCal = (date: any) => {
  return duration(date)
}
// get image
const getUrlImage = useNuxtApp().$urlImage
// swiperBreakpoint
const swiperBreakpoint = {
  320: { slidesPerView: 1, spaceBetween: 10 },
  760: { slidesPerView: 3, spaceBetween: 16 }
}

const recommend = ref<any[]>([])
const isLoading = ref<any>(false)
const updateData = (value: any) => {
  const index = recommend.value.findIndex((i: any) => i._id === value._id)
  recommend.value[index].isSaved = !value.isSaved
}
// feth data
const fetchRecommendJob = async () => {
  try {
    isLoading.value = true
    const body = {
      jobType: 'Recommended',
      verifyToken: authStore.token
    }
    const { data, error } = await useFetchCustom('/job-feature-web')
      .post(body)
      .json()
    if (data.value) {
      const res: any = data.value
      recommend.value = res.jobs
    }
  } catch (e) {
    console.error(e)
  } finally {
    isLoading.value = false
  }
}
//save job function
const handleSaveJob = async (job: any) => {
  try {
    if (!authStore.isAuth) {
      authStore.LoginModal(true)
    } else {
      const index = recommend.value.findIndex((i: any) => i._id === job._id)
      recommend.value[index].isSaved = !job.isSaved
      await saveJob(job._id)
      authStore.LoginModal(false)
    }
  } catch (error) {}
}

// fetching
fetchRecommendJob()
</script>

<style lang="scss">
.recommend-job-card {
  // cursor: pointer;
  box-shadow: var(--job-card-shadow);
  background-color: #fff;
  padding: 1rem;
  border-radius: var(--round-md);
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: all ease-in-out 0.15s;
  max-width: 400px;
  &:hover {
    box-shadow: var(--job-card-shadow-hover);
  }
  .cover {
    position: relative;
    overflow: hidden;
    overflow: hidden;
    span {
      display: block;
      background-color: var(--light-grey-color);
      aspect-ratio: 16/9;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 3rem;
        color: var(--border-color);
      }
    }
    img {
      aspect-ratio: 16/9;
      border-radius: var(--round-sm);
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .card-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .card-content-header {
      position: relative;
      display: flex;
      align-items: center;
      gap: 20px;
      .logo {
        border: 1px solid var(--border-color);
        position: relative;
        max-width: 35px;
        max-height: 35px;
        min-width: 35px;
        min-height: 35px;
        display: flex;
        align-self: flex-end;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        padding: 10px;
        border-radius: 10px;
        img {
          color: var(--grey-color);
          text-align: center;
          display: block;
          object-fit: contain;
          width: 100%;
        }
      }
      .text-info {
        h1 {
          font-weight: 600;
          font-size: 1rem;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
        }
        p {
          color: var(--grey-color);
          font-size: var(--xsm-font);
        }
      }
    }
    .card-content-body {
      h1 {
        text-align: left;
        cursor: pointer;
        font-weight: 700;
        margin-bottom: 1rem;
        font-size: var(--md-font);
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        transition: all 0.1s ease-in-out;
        &:hover {
          color: var(--primary-color);
        }
      }

      .details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding: 1rem 1rem;

        ul {
          text-align: left;
          width: 80%;
          li {
            color: var(--grey-color);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 0.9rem;
            font-size: var(--sm-font);
          }
        } //
        p {
          font-size: var(--sm-font);
        }
      }
    }
  }
}
.loading-container {
  display: flex;
  gap: 1rem;
  width: 100%;
  height: 300px;
}
.saveJob-container {
  position: relative;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 0.25rem;
  white-space: nowrap;
  font-size: var(--sm-font);
  @include mobile {
    padding: 5px 10px;
  }
  .message {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 1px solid red;
  }

  &:hover {
    color: var(--primary-color);

    i {
      font-weight: 700;
    }
  }
  p,
  i {
    text-wrap: nowrap;
  }

  &.saved {
    i {
      color: var(--primary-color);
      font-weight: 900;
    }
  }
}
</style>
