<template>
  <div class="container">
    <div class="section-header">
      <div class="section-header-start">
        <h2 class="title">{{ $t(`${title}`) }}</h2>
      </div>
    </div>
    <div class="loading-container cardlist-loading" v-if="loading">
      <div class="loading-item"></div>
      <div class="loading-item"></div>
      <div class="loading-item"></div>
      <div class="loading-item"></div>
      <div class="loading-item"></div>
    </div>
    <div v-else>
      <div class="swiper-container is-hidden-mobile">
        <client-only>
          <Swiper
            class="card-list-items"
            :modules="[SwiperAutoplay, SwiperNavigation, SwiperPagination]"
            :space-between="16"
            :loop="false"
            :autoplay="{
              delay: 10000,
              disableOnInteraction: true
            }"
            navigation
            :breakpoints="swiperBreakpoint"
            :pagination="{
              el: '.swiper-pagination'
            }"
          >
            <SwiperSlide v-for="(i, index) in data" :key="index">
              <div class="card-item">
                <h3 @click="search(i._id)">
                  {{ i.name }}
                </h3>
                <small>{{ i.jobArvairiable }} {{ $t('job available') }}</small>

                <ul class="logo-list">
                  <li
                    v-for="(idx, index) in filterCompany(i.companiesAssigned)"
                    :key="index"
                  >
                    <div class="logo">
                      <img :src="getUrlImage + idx.logo" alt="logo-company" />
                    </div>
                  </li>
                  <li v-show="filterCompanyTotal(i.companiesAssigned)">
                    <span class="logo"
                      >{{ filterCompanyTotal(i.companiesAssigned) }}+</span
                    >
                  </li>
                </ul>
              </div>
            </SwiperSlide>
            <div class="swiper-pagination"></div>
          </Swiper>
        </client-only>
      </div>
    </div>
  </div>
  <div class="card-list">
    <div
      class="card-item"
      v-for="(i, index) in data"
      :key="index"
      @click="search(i._id)"
    >
      <h3>
        {{ i.name }}
      </h3>
      <small>{{ i.jobArvairiable }} {{ $t('job available') }}</small>

      <ul class="logo-list">
        <li
          v-for="(idx, index) in filterCompany(i.companiesAssigned)"
          :key="index"
        >
          <div class="logo">
            <img :src="getUrlImage + idx.logo" alt="logo-company" />
          </div>
        </li>
        <li v-show="filterCompanyTotal(i.companiesAssigned)">
          <span class="logo"
            >{{ filterCompanyTotal(i.companiesAssigned) }}+</span
          >
        </li>
      </ul>
      <a class="more">Explore</a>
    </div>
  </div>
</template>

<script setup lang="ts">
const getUrlImage = useNuxtApp().$urlImage
// const props = defineProps(['data', 'routeQuery'])
const props = defineProps(['data', 'title', 'routeQuery', 'loading'])
const router = useRouter()

// filter splite
const filterCompany = (data: any) => {
  if (data && data.length > 3) {
    return data.slice(0, 3)
  } else {
    return data || []
  }
}
const filterCompanyTotal = (data: any) => {
  const filteredData = filterCompany(data)
  const totalLength = data.length
  if (totalLength > 0) {
    return totalLength - filteredData.length
  } else {
    return totalLength
  }
}

const search = (id: any) => {
  const routeQ = props.routeQuery
  router.push({
    path: 'job-search',
    query: {
      [routeQ]: id
    }
  })
}

// swiperBreakpoint
const swiperBreakpoint = {
  320: { slidesPerView: 1, spaceBetween: 8 },
  760: { slidesPerView: 3, spaceBetween: 16 },
  1024: { slidesPerView: 4, spaceBetween: 16 },
  1408: { slidesPerView: 5, spaceBetween: 16 }
}
</script>

<style lang="scss">
.card-list {
  overflow-y: auto;
  gap: 1rem;
  padding: 1rem 0;
  margin-top: -1rem;
  display: none;
  @include mobile {
    display: flex;
  }
  .card-item {
    flex-grow: 1;
    min-width: 70%;
    flex-basis: 0;
    @include mobile {
      padding: 1rem;
    }
    .logo-list {
      margin-top: 0.35rem;
      margin-bottom: 0.35rem;
      li {
        .logo {
          max-width: 45px;
          max-height: 45px;
          min-width: 45px;
          min-height: 45px;
          img {
            max-width: 35px;
            max-height: 35px;
            min-width: 35px;
            min-height: 35px;
          }
        }
      }
    }
    .more {
      font-size: var(--sm-font);
      font-weight: 600;
      color: var(--primary-color);
      cursor: pointer;
    }
  }
}
.card-item {
  flex-basis: 0;
  position: relative;
  padding: 1.5rem;
  border-radius: var(--round-md);
  transition: all 0.2s ease-out;
  background-color: #fff;
  box-shadow: var(--card-shadow);
  h3 {
    transition: scale 0.2s ease-out;
    cursor: pointer;
    font-weight: 600;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    font-size: var(--md-font);
    line-height: 1.2;
    &:hover {
      color: var(--primary-color);
    }
  }
  small {
    font-weight: 500;
    font-size: var(--sm-font);
    white-space: pre;
    color: var(--grey-text-color);
  }
  .logo-list {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    list-style: none;
    li {
      flex-basis: 0;
      font-size: var(--sm-font);
      text-align: center;
      .logo {
        background-color: #fff;
        border: 1px solid var(--border-color);
        padding: 0.25rem;
        color: var(--grey-color);
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        aspect-ratio: 1/1;
        max-height: 30px;
        min-width: 30px;
        img {
          display: block;
          object-fit: contain;
          max-height: 30px;
          max-width: 30px;
        }
      }
    }
    &.is-hidden-mobile {
      @include mobile {
        display: none;
      }
    }
  }
}
.cardlist-loading {
  width: 100%;
  height: 60px;
  display: flex;
  gap: 1rem;
}
</style>
